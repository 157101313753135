import App from 'next/app';
import NProgress from 'nprogress';
import Router, { withRouter } from 'next/router';
import 'react-quill/dist/quill.snow.css';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Component } from 'react';
import '../styles/app.css';
import { switchTheme } from '../classes/Dom';
import { UserWrapper } from '../context/UserContext';
import { ironOptions, withSessionSsr } from '../libs/iron-session';
import { getIronSession } from 'iron-session';
import 'react-day-picker/dist/style.css';

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
};

Router.onRouteChangeError = () => {
  NProgress.done();
};

class MyApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        profile: {
          name: '',
        },
      },
      setUser: (user) => {
        localStorage.setItem('user', JSON.stringify(user));
        this.setState({ user });
      },
    };
  }

  refreshProps() {
    const router = this.props.router;

    router.replace(router.asPath);
  }

  componentDidMount() {
    document
      .querySelector('body')
      .classList.add('bg-white', 'dark:bg-gray-900');

    if (!localStorage.theme) localStorage.theme = 'light';
    switchTheme();

    const userFromLocal = localStorage.getItem('user');
    if (userFromLocal) {
      this.setState({ user: JSON.parse(userFromLocal) });
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <UserWrapper sharedState={this.state}>
        <Component {...pageProps} refreshProps={this.refreshProps.bind(this)} />
      </UserWrapper>
    );
  }
}

export default withRouter(MyApp);
