import { withIronSessionApiRoute, withIronSessionSsr } from 'iron-session/next';

export const ironOptions = {
  cookieName: 'kredibelapp',
  password: process.env.SESSION_SECRET,
  cookieOptions: {
    secure: process.env.NODE_ENV === 'production',
  },
};

export function withSessionRoute(handler) {
  return withIronSessionApiRoute(handler, ironOptions);
}

export function withSessionSsr(handler) {
  return withIronSessionSsr(handler, ironOptions);
}
