import { createContext, useContext } from 'react';

export const UserContext = createContext();

export function UserWrapper({ children, sharedState }) {
  return (
    <UserContext.Provider value={sharedState}>{children}</UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
