const byId = (id) => {
  return document.getElementById(id);
};

const el = (query) => {
  return document.querySelector(query);
};

const els = (query) => {
  return document.querySelectorAll(query);
};

const find = (parent, query) => {
  return parent.querySelector(query);
};

const inputByName = (name, parent = document) => {
  return parent.querySelector(`input[name="${name}"]`);
};

const selectByName = (name, parent = document) => {
  return parent.querySelector(`select[name="${name}"]`);
};

const elementByName = (name, parent = document) => {
  return parent.querySelector(`[name="${name}"]`);
};

const putTooltip = (target, text, timeout = 2000) => {
  const id = '_' + Math.random().toString(36).substr(2, 9);

  const element = `
		<div id="${id}" class="the--tooltip fade-in absolute bg-black py-1 px-2 rounded text-sm text-white right-0 z-10 shadow-lg" style="top: calc(100% + 10px)">
			<svg class="absolute text-black" style="top: -8px; right: 10px" xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 24 24" fill="constructor" stroke="constructor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path></svg>
			${text}
		</div>`;

  // prevent duplication
  if (find(target, '.the--tooltip')) return;

  target.insertAdjacentHTML('beforeend', element);

  setTimeout(() => {
    find(target, `#${id}`).remove();
  }, timeout);
};

const switchTheme = () => {
  if (
    localStorage.theme === 'dark' ||
    (!('theme' in localStorage) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export {
  byId,
  el,
  els,
  find,
  inputByName,
  selectByName,
  putTooltip,
  elementByName,
  switchTheme,
};
